/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonMenu {
    classItem = 'button-menu--item'
    classButton = 'button-menu--button'

    classActive = 'active'

    constructor () {
        let self = this
        // let windowWidth = parseInt(window.innerWidth)

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classActive)
            $(this).closest(`.${self.classItem}`).toggleClass(self.classActive)
        })

        /*
        if ($(`.${self.classButton}`).length > 0) {
            console.log(11)
            if (windowWidth <= 640) {
                console.log(22)
                $(`.${self.classButton}`).toggleClass(self.classActive)
                $(`.${self.classButton}`).closest(`.${self.classItem}`).toggleClass(self.classActive)
                // $(`.${self.classButton}`).click()
            }else {
                console.log(33)
                $(`.${self.classButton}:eq(0)`).toggleClass(self.classActive)
                $(`.${self.classButton}:eq(0)`).closest(`.${self.classItem}`).toggleClass(self.classActive)
                // $(`.${self.classButton}:eq(0)`).click()
            }
        }
        */
    }
}
