/*global $*/
/*eslint no-undef: "error"*/
export default class Short {
    classButton = 'short--button'
    classHeader = 'page---header'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            let heightHeader = $(`.${self.classHeader}`).height()

            $('html,body').stop().animate({
                scrollTop: $(`#short-${block}`).offset().top - heightHeader - 25
            }, 500)

            console.log(block, heightHeader)
        })
    }
}
