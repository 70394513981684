/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createDoctor () {
        return new Swiper('#slider--doctor', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--doctor-next',
                prevEl: '.slider--doctor-prev',
            },
            pagination: {
                el: '.swiper-pagination--doctor',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }
    createTrust () {
        return new Swiper('#slider--trust', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--trust-next',
                prevEl: '.slider--trust-prev',
            },
            pagination: {
                el: '.swiper-pagination--trust',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }
    createReview () {
        return new Swiper('#slider--review', {
            slidesPerView: 20,
            spaceBetween: 1,
            // loop: true,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                }
            }
        })
    }
    createService () {
        return new Swiper('#slider--service', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--service-next',
                prevEl: '.slider--service-prev',
            },
            pagination: {
                el: '.swiper-pagination--service',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 8
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 14,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 14,
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 14,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 14,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 14,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }
    createPrice (tabs) {
        return new Swiper('#slider--price', {
            slidesPerView: 20,
            spaceBetween: 1,
            // loop: true,
            navigation: {
                nextEl: '.slider--price-next',
                prevEl: '.slider--price-prev',
            },
            pagination: {
                el: '.swiper-pagination--price',
                clickable: true,
                bulletActiveClass:'button-menu---active',
                renderBullet: function (i, classes) {
                    return `<div class="button-menu ${classes}"><div class="button-menu__text">${tabs[i]}</div></div>`
                }
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                }
            }
        })
    }
    createDependence () {
        return new Swiper('#slider--dependence', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--dependence-next',
                prevEl: '.slider--dependence-prev',
            },
            pagination: {
                el: '.swiper-pagination--dependence',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createStep () {
        return new Swiper('#slider--step', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--step-next',
                prevEl: '.slider--step-prev',
            },
            pagination: {
                el: '.swiper-pagination--step',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createConsult (tabs) {
        return new Swiper('#slider--consult', {
            slidesPerView: 20,
            spaceBetween: 1,
            // loop: true,
            navigation: {
                nextEl: '.slider--consult-next',
                prevEl: '.slider--consult-prev',
            },
            pagination: {
                el: '.swiper-pagination--consult',
                clickable: true,
                bulletActiveClass:'button-menu---active',
                renderBullet: function (i, classes) {
                    return `<div class="button-menu button-menu---small ${classes}"><div class="button-menu__text">${tabs[i]}</div></div>`
                }
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                }
            }
        })
    }
    createMedication (tabs) {
        return new Swiper('#slider--medication', {
            slidesPerView: 20,
            spaceBetween: 1,
            // loop: true,
            navigation: {
                nextEl: '.slider--medication-next',
                prevEl: '.slider--medication-prev',
            },
            pagination: {
                el: '.swiper-pagination--medication',
                clickable: true,
                bulletActiveClass:'button-menu---active',
                renderBullet: function (i, classes) {
                    return `<div class="button-menu button-menu---line button-menu---small ${classes}"><div class="button-menu__text">${tabs[i]}</div></div>`
                }
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                }
            }
        })
    }
    createGallery () {
        return new Swiper('#slider--gallery', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createSert () {
        return new Swiper('#slider--sert', {
            slidesPerView: 20,
            spaceBetween: 6,
            // loop: true,
            navigation: {
                nextEl: '.slider--sert-next',
                prevEl: '.slider--sert-prev',
            },
            pagination: {
                el: '.swiper-pagination--sert',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                }
            }
        })
    }
    createRecord () {
        return new Swiper('#slider--record', {
            slidesPerView: 20,
            spaceBetween: 2,
            // loop: true,
            navigation: {
                nextEl: '.slider--record-next',
                prevEl: '.slider--record-prev',
            },
            pagination: {
                el: '.swiper-pagination--record',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }
    createLis () {
        return new Swiper('#slider--lis', {
            slidesPerView: 5,
            spaceBetween: 5,
            // loop: true,
            navigation: {
                nextEl: '.slider--lis-next',
                prevEl: '.slider--lis-prev',
            },
            pagination: {
                el: '.swiper-pagination--lis',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                320: {
                    slidesPerView: 5,
                    spaceBetween: 5
                },
                360: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                375: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                414: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                480: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                514: {
                    slidesPerView: 5,
                    spaceBetween: 5
                },
                640: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                991: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                }
            }
        })
    }
    createArticle () {
        return new Swiper('#slider--article', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--article-next',
                prevEl: '.slider--article-prev',
            },
            pagination: {
                el: '.swiper-pagination--article',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if (parseInt($('#slider--doctor').length) > 0) {
            this.createDoctor()
        }

        if (parseInt($('#slider--trust').length) > 0) {
            this.createTrust()
        }

        if (parseInt($('#slider--review').length) > 0) {
            this.createReview()
        }

        if (parseInt($('#slider--service').length) > 0) {
            this.createService()
        }

        if (parseInt($('#slider--price').length) > 0) {
            let items = document.querySelectorAll('.table-price---slide')
            let tabs = []
            items.forEach(slide => {
                let curTab = slide.dataset.tab
                tabs.push(curTab)
            })

            this.createPrice(tabs)
        }

        if (parseInt($('#slider--dependence').length) > 0) {
            this.createDependence()
        }

        if (parseInt($('#slider--step').length) > 0) {
            this.createStep()
        }

        if (parseInt($('#slider--consult').length) > 0) {
            let items = document.querySelectorAll('.doctor---slide')
            let tabs = []
            items.forEach(slide => {
                let curTab = slide.dataset.tab
                tabs.push(curTab)
            })

            this.createConsult(tabs)
        }

        if (parseInt($('#slider--medication').length) > 0) {
            let items = document.querySelectorAll('.medication-cart---slide')
            let tabs = []
            items.forEach(slide => {
                let curTab = slide.dataset.tab
                tabs.push(curTab)
            })

            this.createMedication(tabs)
        }

        if (parseInt($('#slider--gallery').length) > 0) {
            this.createGallery()
        }

        if (parseInt($('#slider--sert').length) > 0) {
            this.createSert()
        }

        if (parseInt($('#slider--record').length) > 0) {
            this.createRecord()
        }

        if (parseInt($('#slider--lis').length) > 0) {
            this.createLis()
        }

        if (parseInt($('#slider--article').length) > 0) {
            this.createArticle()
        }
    }
}
